.attendance-image{
    border: 1px solid #1a237e;
    
}

.cam-buttons{
    padding: 24px !important;
    min-width: 150px !important;; 
    height: 50px !important;
    font-size: 18px !important;
}
.countdown{
    font-size: 75px;
    font-weight: bold;
    color: white;
    z-index: 1000;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 4px rgb(0, 0, 0);
    text-align: center;
    width: 640px;
}

@media  screen and (orentation: landscape) {
    .attendance-image{
        height: 480px;
        width: 640px;
    }
}
@media screen and (orentation: portrait) {
    .attendance-image{
        height: 640px;
        width: 480px;
    }
}
    

    
