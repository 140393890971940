.btnContainer {
  margin-top: 5px;
  text-align: center;
}
.btn {
  height: 70px !important;
  width: 70px !important;
  font-size: 1.5rem !important;
  border-radius: 20% !important;
  margin: 8px !important;
  border: 1px solid #ffffff3e !important;
  color: rgb(226, 226, 226) !important;
  background-color: #101010cf !important;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.316) !important;
}
.btn:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
.pin{
  width: 60px;
  border: 0;
  word-break: break-all;
  font-size: 17px;
  border-bottom: 1px solid #0004ff;
}
.pin:hover{
  border-bottom: 2px solid #0004ff;
}
.pin:focus{
  outline: none;
  border-bottom: 2px solid #0004ff;
}
.pin-input{
  width: 250px !important;
  letter-spacing: 50px !important;
  word-spacing: 50px !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root{
  min-height: 0!important;
}

.svg_icons {
  transform: scale(1.5);
}
.dialogue-border-radius{
  border-radius: 16px !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper{
  border-radius: 16px!important;
}
.login-container {
  animation: customShadow 3s infinite;
}

/* @keyframes customShadow {
  0% {
    box-shadow: 0 0 150px 0.5px rgba(0, 208, 255, 1);
  }
  25% {
    box-shadow: 0 0 150px 0.5px rgb(78, 213, 244);
  }
  50% {
    box-shadow: 0 0 150px 0.5px rgb(202, 245, 255);
  }
  75% {
    box-shadow: 0 0 150px 0.5px rgb(78, 213, 244);
  }
  100% {
    box-shadow: 0 0 150px 0.5px rgba(0, 208, 255, 1);
  }
} */


@keyframes customShadow {
  0% {
    box-shadow: 0 0 150px 0.5px rgba(0, 208, 255, 1);
  }
  25% {
    box-shadow: 0 0 150px 0.5px rgb(78, 213, 244);
  }
  50% {
    box-shadow: 0 0 150px 0.5px rgb(202, 245, 255);
  }
  75% {
    box-shadow: 0 0 150px 0.5px rgb(146, 231, 250);
  }
  100% {
    box-shadow: 0 0 150px 0.5px rgba(0, 208, 255, 1);
  }
  
}

