
.shift-approved{
    background-color: #fff;
    position: relative;
    cursor: pointer;
    width: 180px;
    
  }
  
  .shift-data{
    background-color: rgb(219 242 220);
    border: 1px solid rgb(129 199 132);
    border-left: 5px solid rgb(129 199 132);
    border-radius: 5px;
    padding: 0;
  }